<template>
  <v-container fluid>
    <v-tabs>
      <v-tab>Users</v-tab>
      <v-tab-item>
        <Users></Users>
      </v-tab-item>
      <v-tab>Holidays</v-tab>
      <v-tab-item>
        <Holiday></Holiday>
      </v-tab-item>
      <v-tab>Activity Codes</v-tab>
      <v-tab-item>
        <ActivityCodes></ActivityCodes>
      </v-tab-item>
      <v-tab>Audit</v-tab>
      <v-tab-item>
        <Audit></Audit>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import Users from '@components/admin/users.vue'
import Holiday from '@components/admin/Holiday.vue'
import ActivityCodes from '@components/admin/ActivityCodes.vue'
import Audit from '@components/admin/audit.vue'
export default {
  page: {
    title: 'Admin',
  },
  components: {Users, Holiday, Audit, ActivityCodes},
  //model: {
  //	prop: ''
  //},
  props: {},
  filters: {},
  data() {
    return {}
  },
  created() {},
  computed: {},
  methods: {},
  watch: {},
}
</script>
<style lang="scss">
//@import '@design';
</style>