<template>
  <v-row>
    <v-col sm="12">
      <v-card class="mx-auto">
        <v-list-item class="primary">
          <v-list-item-content>
            <v-list-item-title class="headline text-left white--text"
              >Holiday</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-card-text>
          <v-row justify="end">
            <v-col sm="auto">
              <v-text-field
                v-model="searchText"
                prepend-inner-icon="mdi-magnify"
                label="Search"
                clearable
              ></v-text-field>
            </v-col>
            <v-col sm="auto" class="mt-4 mr-4">
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn
                    color="primary"
                    v-on="on"
                    fab
                    @click="addHoliday"
                    depressed
                    small
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add Holiday</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-dialog
            persistent
            v-model="dialog"
            @keydown.esc="dialog = false"
            width="850"
            scrollable
          >
            <v-form
              ref="holidayForm"
              @submit.prevent
              lazy-validation
              v-model="valid"
            >
              <v-card class="elevation-3">
                <v-card-title class="primary">
                  <h3 class="hpHeading white--text">{{ modalText }}</h3>
                </v-card-title>
                <v-card-text class="pa-4">
                  <!-- <v-row no-gutters>
                    <v-col sm="12" md="6" class="pr-1">
                      <v-text-field
                        label="ID"
                        v-model="selectedHoliday.ID"
                        type="number"
                        :rules="[v => !!v || 'required']"
                      ></v-text-field>
                    </v-col>
                  </v-row>-->
                  <v-row no-gutters>
                    <v-col sm="12" md="6" class="pr-1">
                      <BaseDatePickerWithText
                        label="Date mm/dd/yyyy"
                        v-model="selectedHoliday.Date"
                        :rules="[(v) => !!v || 'required']"
                      ></BaseDatePickerWithText>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col sm="12" md="6" class="pr-1">
                      <v-text-field
                        label="Description"
                        v-model="selectedHoliday.Description"
                        :rules="[(v) => !!v || 'required']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    @click="saveHolidayDetails"
                    :disabled="saving"
                    color="primary"
                    >Accept</v-btn
                  >
                  <v-btn
                    outlined
                    color="primary"
                    @click="
                      dialog = !dialog
                      $refs.holidayForm.reset()
                    "
                    >Cancel</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-card-text>
        <template>
          <v-data-table
            :headers="filteredHeaders"
            :items="holiday"
            :search="searchText"
            :sort-by.sync="pagination.sortBy"
            :sort-desc.sync="pagination.descending"
            class="pt-4"
            dense
          >
            <template v-slot:item.edit="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn
                    small
                    color="primary"
                    v-on="on"
                    icon
                    @click="editHoliday(item)"
                  >
                    <v-icon class="clickable" color="primary"
                      >mdi-pencil</v-icon
                    >
                  </v-btn>
                </template>
                <span>Edit Holiday Information</span>
              </v-tooltip>
            </template>
            <template v-slot:item.delete="{item}">
              <v-tooltip v-if="item.ID > 0" bottom>
                <template v-slot:activator="{on}">
                  <v-btn
                    small
                    color="primary"
                    v-on="on"
                    icon
                    @click="deleteConfirm(item)"
                  >
                    <v-icon class="clickable" color="primary"
                      >mdi-delete</v-icon
                    >
                  </v-btn> </template
                >7
                <span>Delete Holiday</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-dialog
            persistent
            v-model="deleteDialog"
            @keydown.esc="deleteDialog = false"
            width="350"
            scrollable
          >
            <v-card class="elevation-3">
              <v-card-title class="primary">
                <h3 class="hpHeading white--text">Confirm?</h3>
              </v-card-title>
              <v-card-text class="pa-4">
                <v-row>
                  <v-col sm="12"
                    >Are you sure you want to delete this holiday?</v-col
                  >
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" @click="deleteDialog = !deleteDialog"
                  >Cancel</v-btn
                >
                <v-btn @click="callDelete" outlined color="primary"
                  >Delete</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'

import Holiday from '@classes/Holiday'
export default {
  components: {},
  data: () => ({
    valid: true,
    selectedHoliday: new Holiday(),
    searchText: '',
    dialog: false,
    deleteDialog: false,
    modalText: '',
    pagination: {sortBy: 'Date', descending: true},
    saving: false,
  }),
  created() {
    this.loadHoliday()
  },
  computed: {
    holiday: get('holiday/holiday'),
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'ID',
          align: 'center',
          sortable: 'true',
          value: 'ID',
          visible: false,
        },
        {
          text: 'Date',
          align: 'center',
          sortable: 'true',
          value: 'Date',
          visible: true,
        },
        {
          text: 'Description',
          align: 'center',
          sortable: 'true',
          value: 'Description',
          visible: true,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: true,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    handleError: call('errors/handleError'),
    saveHoliday: call('holiday/saveHoliday'),
    deleteHoliday: call('holiday/deleteHoliday'),
    loadHoliday: call('holiday/loadHoliday'),
    async validate() {
      this.$refs.holidayForm.validate()
    },
    editHoliday(entry) {
      this.selectedHoliday = new Holiday(entry)
      this.modalText = 'Edit Holiday'
      this.dialog = !this.dialog
    },
    addHoliday() {
      this.modalText = 'Insert Holiday'
      this.selectedHoliday = new Holiday()
      this.dialog = !this.dialog
    },
    deleteConfirm(item) {
      this.selectedHoliday = item
      this.deleteDialog = true
    },
    callDelete() {
      this.deleteHoliday(this.selectedHoliday).then(
        (res) => {
          this.loadHoliday()
          this.deleteDialog = false
        },
        (err) => {}
      )
    },
    saveHolidayDetails() {
      this.validate().then(() => {
        if (this.valid) {
          this.saving = true
          this.saveHoliday(this.selectedHoliday.removeRelated()).then(
            (res) => {
              this.loadHoliday()
              this.saving = false
              this.$refs.holidayForm.reset()
            },
            (err) => {
              this.saving = false
            }
          )
          this.dialog = false
        }
      })
    },
  },
}
</script>
<style>
</style>