<template>
  <v-row>
    <v-col sm="12">
      <v-card class="mx-auto">
        <v-list-item class="primary">
          <v-list-item-content>
            <v-list-item-title class="headline text-left white--text"
              >Audit</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-card-text>
          <v-row>
            <v-col sm="12" md="3" p>
              <BaseDatePickerWithText
                label="Date Range Start"
                v-model="startDate"
              ></BaseDatePickerWithText>
            </v-col>
            <v-col sm="12" md="3">
              <BaseDatePickerWithText
                label="Date Range End"
                v-model="endDate"
              ></BaseDatePickerWithText>
            </v-col>
            <v-col sm="12" md="3" class="mt-3">
              <v-autocomplete
                v-model="entityName"
                :items="availableEntityNames"
                label="Entity Name"
                chips
                deletable-chips
                small-chips
                clearable
                multiple
                dense
              ></v-autocomplete>
            </v-col>
            <v-col sm="12" md="3" class="mt-3">
              <v-autocomplete
                v-model="operation"
                :items="availableOperations"
                label="Operation"
                chips
                deletable-chips
                small-chips
                clearable
                multiple
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="3" class="mt-3">
              <v-autocomplete
                v-model="users"
                :items="userList"
                :search-input.sync="userSearchText"
                :loading="userSearchIsLoading"
                placeholder="Start typing to Search"
                item-text="fullName"
                item-value="ID"
                label="Employee"
                cache-items
                chips
                deletable-chips
                small-chips
                clearable
                multiple
                dense
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title
                      >No results for search query</v-list-item-title
                    >
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col sm="12" md="3">
              <v-text-field
                v-model="searchText"
                prepend-inner-icon="mdi-account-search"
                label="Search"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <template>
          <v-data-table
            item-key="ID"
            :server-items-length="total"
            :loading="loading"
            :headers="filteredHeaders"
            :items="dataDescription"
            :sort-by.sync="pagination.sortBy"
            :sort-desc.sync="pagination.descending"
            :page.sync="pagination.page"
            :items-per-page.sync="pagination.rowsPerPage"
            :expanded.sync="expanded"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 25, 50],
            }"
            single-expand
            show-expand
            class="pt-4"
            dense
          >
            <template v-slot:item.ChangedOn="{item}">
              <span>{{ item.ChangedOn | dateTime }}</span>
            </template>
            <template v-slot:group.header.data-table-expand="{on, props}">
              <span>Expand</span>
            </template>
            <template v-slot:item.Value="{item}">
              <span @click="expandRow(item)">{{
                item.Value | snippet(50)
              }}</span>
            </template>
            <template v-slot:expanded-item="{item, headers}">
              <td :colspan="headers.length" class="pa-2">{{
                item.Value | prettyJson
              }}</td>
            </template>
          </v-data-table>
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import {authMethods, authComputed} from '@state/helpers'
import dayjs from 'dayjs'
export default {
  components: {},
  data() {
    return {
      searchText: '',
      search: '',
      startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
      audit: [],
      users: [],
      operation: [],
      entityName: [],
      availableOperations: ['Update', 'Create', 'AddAll', 'Delete'],
      availableEntityNames: [
        'ActivityCodes',
        'ApprovalStatus',
        'AuditEntity',
        'AuditEntityDivision',
        'AuditEntityPublicFunds',
        'AuditSubEntity',
        'Contact',
        'Division',
        'EmployeePayType',
        'EmployeeStatus',
        'EmployeeType',
        'EntityAddress',
        'EntityContact',
        'FinancialReport',
        'Holiday',
        'HolidayEntry',
        'IncludedWith',
        'LeaveSlip',
        'ProjectBanking',
        'ProjectBudgetedHours',
        'ProjectCollections',
        'Project',
        'ProjectDate',
        'ProjectDetail',
        'ProjectLicense',
        'ProjectExpenses',
        'ProjectFindings',
        'ProjectNote',
        'ProjectRefunds',
        'ProjectReportedValues',
        'ProjectReviews',
        'RequirementLevel',
        'Role',
        'SubDivision',
        'SubEntityContact',
        'SystemMessage',
        'TimeSheet',
        'TimeSheetEntry',
        'TimeSheetStatus',
        'User',
        'UserProject',
        'UserRole',
        'WorkWeekType',
      ],
      userSearchText: '',
      userSearchCount: 0,
      userList: [],
      total: 0,
      searchCount: 0,
      allAudit: false,
      expanded: [],
      singleExpand: false,
      panel: [0, 1],
      total: 0,
      pagination: {
        page: 1,
        sortBy: 'EntityName',
        descending: false,
        rowsPerPage: 25,
      },
    }
  },
  created() {
    this.searchAudit()
    this.bounce(this)
  },
  computed: {
    ...authComputed,
    headers() {
      return [
        {
          text: 'Audit Name',
          align: 'center',
          sortable: 'true',
          value: 'EntityName',
          visible: true,
          filterable: true,
        },
        {
          text: 'Operation',
          align: 'center',
          sortable: 'true',
          value: 'Operation',
          visible: true,
          filterable: true,
        },
        {
          text: 'Changed By',
          align: 'center',
          sortable: 'true',
          value: 'fullName',
          visible: true,
          filterable: true,
        },
        {
          text: 'Changed On',
          align: 'center',
          sortable: 'true',
          value: 'ChangedOn',
          visible: true,
          filterable: true,
        },
        {
          text: 'Value',
          align: 'center',
          sortable: 'true',
          value: 'Value',
          visible: true,
          filterable: true,
        },
        {text: '', value: 'data-table-expand', visible: true},
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    dataDescription() {
      var data = this.audit.map((dd) => {
        return {
          ...dd,
          fullName: dd.EnteredByNavigation
            ? dd.EnteredByNavigation.FirstName +
              ' ' +
              dd.EnteredByNavigation.LastName
            : 'unk.',
        }
      })
      return data
    },
    loading() {
      return this.searchCount > 0
    },
    userSearchIsLoading() {
      return this.userSearchCount > 0
    },
    searchParams() {
      return {
        startDate: this.startDate,
        endDate: this.endDate,
        sortProperty: this.pagination.sortBy,
        sortDirection: this.pagination.descending ? 'desc' : 'asc',
        searchText: this.searchText,
        users: this.users,
        entityName: this.entityName,
        operation: this.operation,
      }
    },
    userSearchParams() {
      if (!!this.userSearchText) {
        return {
          searchText: this.userSearchText,
        }
      } else {
        return null
      }
    },
  },
  methods: {
    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item]
    },
    searchAudit() {
      if (this.currentUser) {
        if (!this.pagination.sortBy) {
          this.pagination.sortBy = 'EntityName'
          this.pagination.descending = false
        }
        if (!this.searchText) {
          this.searchText = ''
        }
        this.searchCount++
        const url = `AuditSearch?pageNum=${this.pagination.page - 1}&pageSize=${
          this.pagination.rowsPerPage
        }&searchText=${JSON.stringify(this.searchParams)}`
        this.$axios.get(url).then(
          (res) => {
            // this.pagination.descending = res.data.sortDirection == 'desc'
            this.pagination.rowsPerPage = res.data.Page.Size
            this.pagination.page = res.data.Page.Number + 1
            this.total = res.data.Page.TotalElements
            this.audit = res.data.Audit
            this.searchCount--
          },
          (error) => {
            this.error = error
            this.handleError(error)
            console.error(error)
            this.searchCount--
          }
        )
      }
    },
    bounce: _.debounce((self) => {
      self.searchAudit()
    }, 750),
    searchUsers() {
      if (!!this.userSearchParams) {
        const url = `User/Lookup?search=${JSON.stringify(
          this.userSearchParams
        )}`
        this.userSearchCount++
        this.$axios.get(url).then(
          (res) => {
            this.userList = res.data.map((item) => {
              return {
                ...item,
                fullName: item.FirstName + ' ' + item.LastName,
              }
            })
            this.userSearchCount--
          },
          (error) => {
            this.handleError(error)
            console.error(error)
            this.userSearchCount--
          }
        )
      } else this.userList = []
    },
    userSearchBounce: _.debounce((self) => {
      self.searchUsers()
    }, 500),
  },
  watch: {
    pagination: {
      handler() {
        this.bounce(this)
      },
      deep: true,
    },
    searchText: {
      handler(nval, oval) {
        this.bounce(this)
      },
    },
    startDate: {
      handler() {
        this.bounce(this)
      },
    },
    endDate: {
      handler() {
        this.bounce(this)
      },
    },
    operation: {
      handler(nval, oval) {
        this.bounce(this)
      },
    },
    entityName: {
      handler(nval, oval) {
        this.bounce(this)
      },
    },
    users: {
      handler(nval, oval) {
        this.bounce(this)
      },
    },
    userSearchText: {
      handler() {
        this.userSearchBounce(this)
      },
    },
    allAudit: {
      handler() {
        this.bounce(this)
      },
    },
  },
}
</script>
<style scoped>
.v-data-table tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;
  max-width: 50px;
}
</style>
