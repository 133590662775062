<template>
  <v-row>
    <v-col sm="12">
      <v-card class="mx-auto">
        <v-list-item class="primary">
          <v-list-item-content>
            <v-list-item-title class="headline text-left white--text"
              >Users</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-card-text>
          <v-row justify="end">
            <v-col sm="auto">
              <v-text-field
                v-model="searchText"
                prepend-inner-icon="mdi-account-search"
                label="Search"
                clearable
              ></v-text-field>
            </v-col>
            <v-col sm="auto" class="mt-2">
              <v-checkbox v-model="allUsers" label="All Users?"></v-checkbox>
            </v-col>
            <v-col sm="auto" class="mt-4 mr-4">
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn
                    color="primary"
                    v-on="on"
                    fab
                    @click="newUser"
                    depressed
                    small
                  >
                    <v-icon dark>mdi-account-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add User</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-dialog
            persistent
            v-model="dialog"
            @keydown.esc="dialog = false"
            width="850"
            scrollable
          >
            <v-form
              ref="userForm"
              @submit.prevent
              lazy-validation
              v-model="valid"
            >
              <v-card class="elevation-3">
                <v-card-title class="primary">
                  <h3 class="hpHeading white--text">{{ modalText }}</h3>
                </v-card-title>
                <v-card-text class="pa-4">
                  <v-row no-gutters>
                    <v-col sm="12" md="6" class="pr-1">
                      <v-text-field
                        label="First Name"
                        v-model="selectedUser.FirstName"
                        :rules="[(v) => !!v || 'required', rules.namePattern]"
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-text-field
                        label="Last Name"
                        v-model="selectedUser.LastName"
                        :rules="[(v) => !!v || 'required', rules.namePattern]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col sm="12" md="6" class="pr-1">
                      <v-text-field
                        label="Email Address"
                        v-model="selectedUser.eMail"
                        :rules="[(v) => !!v || 'required', rules.emailPattern]"
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-text-field
                        label="Employee Id"
                        v-model="selectedUser.EmployeeID"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col sm="12" md="6" class="pr-1">
                      <v-select
                        v-model="selectedUserRoles"
                        :items="role"
                        item-text="Description"
                        item-value="ID"
                        attach
                        chips
                        label="Role"
                        deletable-chips
                        multiple
                      ></v-select>
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-select
                        v-model="selectedUser.DivisionID"
                        :items="availableDivisions"
                        item-text="DivisionName"
                        item-value="ID"
                        label="Division"
                        chips
                        deletable-chips
                        :rules="[(v) => !!v || 'required']"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col sm="12" md="6" class="pr-1">
                      <v-select
                        v-model="selectedUser.EmployeeTypeID"
                        :items="employeeType"
                        item-text="Description"
                        item-value="ID"
                        attach
                        label="Employee Type"
                        :rules="[(v) => !!v || 'required']"
                        clearable
                      ></v-select>
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-select
                        v-model="selectedUser.WorkWeekTypeID"
                        :items="workWeekType"
                        item-text="Description"
                        item-value="ID"
                        attach
                        label="Work Week Type"
                        :rules="[(v) => !!v || 'required']"
                        clearable
                      ></v-select>
                    </v-col>
                    <v-col
                      sm="12"
                      md="6"
                      v-if="selectedUser.EmployeeTypeID == 2"
                    >
                      <!-- NEED TO ADD V-MODEL -->
                      <v-select
                        :items="employeePayType"
                        item-text="Description"
                        item-value="ID"
                        v-model="selectedUser.EmployeePayTypeID"
                        label="Compensation Type"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col sm="12" md="6" class="pr-1">
                      <v-select
                        v-model="selectedUser.SupervisorID"
                        :items="availableSupervisors"
                        item-value="ID"
                        attach
                        label="Supervisor"
                        clearable
                      >
                        <template v-slot:selection="{item, index}">
                          <span>{{ item.FirstName }} {{ item.LastName }}</span>
                        </template>
                        <template v-slot:item="{item, index}">
                          <span>{{ item.FirstName }} {{ item.LastName }}</span>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-select
                        v-model="selectedUser.TimesheetVerifierID"
                        :items="availableValidators"
                        item-value="ID"
                        attach
                        label="Timesheet Verifier"
                        clearable
                      >
                        <template v-slot:selection="{item, index}">
                          <span>{{ item.FirstName }} {{ item.LastName }}</span>
                        </template>
                        <template v-slot:item="{item, index}">
                          <span>{{ item.FirstName }} {{ item.LastName }}</span>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col sm="12" md="6" class="pr-1">
                      <v-select
                        v-model="selectedUser.DirectorID"
                        :items="availableDirectors"
                        item-value="ID"
                        attach
                        label="Director"
                        clearable
                      >
                        <template v-slot:selection="{item, index}">
                          <span>{{ item.FirstName }} {{ item.LastName }}</span>
                        </template>
                        <template v-slot:item="{item, index}">
                          <span>{{ item.FirstName }} {{ item.LastName }}</span>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-select
                        v-model="selectedUser.AdministratorID"
                        :items="availableAdministrators"
                        item-value="ID"
                        attach
                        label="Administrator"
                        clearable
                      >
                        <template v-slot:selection="{item, index}">
                          <span>{{ item.FirstName }} {{ item.LastName }}</span>
                        </template>
                        <template v-slot:item="{item, index}">
                          <span>{{ item.FirstName }} {{ item.LastName }}</span>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col sm="12" md="6" class="pr-1">
                      <v-select
                        v-model="selectedUser.EmployeeStatusID"
                        :items="employeeStatus"
                        item-text="Description"
                        item-value="ID"
                        attach
                        label="Status"
                        clearable
                      ></v-select>
                      <!-- :rules="[v => !!v || 'required']" -->
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    @click="saveUserDetails"
                    :disabled="saving"
                    color="primary"
                    >Accept</v-btn
                  >
                  <v-btn
                    outlined
                    color="primary"
                    @click="
                      dialog = !dialog
                      $refs.userForm.reset()
                    "
                    >Cancel</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-card-text>
        <template>
          <v-data-table
            :headers="filteredHeaders"
            :items="users"
            :server-items-length="total"
            :loading="loading"
            :sort-by.sync="pagination.sortBy"
            :sort-desc.sync="pagination.descending"
            :page.sync="pagination.page"
            :items-per-page.sync="pagination.rowsPerPage"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 25, 50],
            }"
            class="pt-4"
            dense
          >
            <!-- :options.sync="pagination" -->
            <template v-slot:item.view="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn
                    small
                    color="primary"
                    v-on="on"
                    icon
                    @click="editUser(item)"
                  >
                    <v-icon class="clickable" color="primary"
                      >mdi-pencil</v-icon
                    >
                  </v-btn>
                </template>
                <span>Edit User</span>
              </v-tooltip>
            </template>
            <template v-slot:item.LastName="{item}">{{
              item.LastName
            }}</template>
            <template v-slot:item.FirstName="{item}">{{
              item.FirstName
            }}</template>
            <template v-slot:item.eMail="{item}">{{ item.eMail }}</template>
            <template
              v-if="!allUsers"
              v-slot:item.EmployeeStatus.Description="{item}"
              >{{ item.EmployeeStatus.Description }}</template
            >
            <template v-slot:item.delete="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn small color="primary" v-on="on" icon>
                    <v-icon
                      class="clickable"
                      color="primary"
                      @click="deleteConfirm(item)"
                      >mdi-trash-can</v-icon
                    >
                  </v-btn>
                </template>
                <span>Delete User</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-dialog persistent v-model="deleteDialog" scrollable width="350">
            <v-card class="elevation-3">
              <v-card-title class="primary">
                <h3 class="hpHeading white--text">Confirm?</h3>
              </v-card-title>
              <v-card-text class="pa-4">
                <v-row>
                  <v-col sm="12"
                    >Are you sure you want to delete this user?</v-col
                  >
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" @click="deleteDialog = !deleteDialog"
                  >Cancel</v-btn
                >
                <v-btn @click="callDelete" outlined color="primary"
                  >Delete</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>

<!-- SCRIPTS -->
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import {authMethods, authComputed} from '@state/helpers'
import {debounce, range} from 'lodash'
import User from '@classes/User'
import UserRole from '@classes/UserRole'
import Rules from '@validation/rules'
export default {
  data: () => ({
    valid: true,
    selectedUser: new User(),
    rules: Rules,
    searchText: '',
    allUsers: false,
    users: [],
    dialog: false,
    modalText: '',
    deleteDialog: false,
    total: 0,
    searchCount: 0,
    pagination: {
      page: 1,
      sortBy: 'LastName',
      descending: false,
      rowsPerPage: 25,
    },
    saving: false,
  }),
  created() {
    this.searchUsers()
    this.loadRoles()
    this.loadDivisions()
    this.loadEmployeeTypes()
    this.loadEmployeePayTypes()
    this.loadWorkWeekType()
    this.loadEmployeeStatus()
    this.loadUserLookup()
  },
  computed: {
    ...authComputed,
    role: get('role/role'),
    division: get('division/division'),
    employeeType: get('employeeType/employeeType'),
    employeePayType: get('employeePayType/employeePayType'),
    workWeekType: get('workWeekType/workWeekType'),
    employeeStatus: get('employeeStatus/employeeStatus'),
    userLookup: get('user/userLookup'),
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'view',
          visible: true,
          sortable: false,
        },
        {
          text: 'Last Name',
          align: 'center',
          sortable: 'true',
          value: 'LastName',
          visible: true,
        },
        {
          text: 'Fist Name',
          align: 'center',
          sortable: 'true',
          value: 'FirstName',
          visible: true,
        },
        {
          text: 'Email',
          align: 'center',
          sortable: 'true',
          value: 'eMail',
          visible: true,
        },
        {
          text: 'Active?',
          align: 'center',
          sortable: 'true',
          value: 'EmployeeStatus.Description',
          visible: this.allUsers,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: true,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    availableDivisions() {
      if (this.selectedUser.ID > 0) {
        return this.division
      } else {
        return this.division.filter((d) => d.DivisionStatus)
      }
    },
    availableWorkWeekTypes() {
      if (this.selectedUser.ID > 0) {
        return this.workWeekType
      } else {
        return this.workWeekType.filter((w) => w.Active)
      }
    },
    availableDirectors() {
      let directors = this.userLookup.filter(
        (u) =>
          // u.DivisionID == this.selectedUser.DivisionID &&
          !!u.UserRole.find((ur) => ur.RoleID == 2)
      )
      // if (this.selectedUser.ID > 0 && this.selectedUser.DirectorID > 0) {
      //   let selectedDirector = this.userLookup.find(
      //     (u) => u.ID == this.selectedUser.DirectorID
      //   )
      //   if (
      //     !directors.find((v) => v.ID == this.selectedUser.DirectorID) &&
      //     selectedDirector
      //   ) {
      //     directors.push(selectedDirector)
      //   }
      // }
      directors.sort((a, b) => {
        a.FullName > b.FullName
      })
      return directors
    },
    availableAdministrators() {
      let administrators = this.userLookup.filter(
        (u) =>
          u.DivisionID == this.selectedUser.DivisionID &&
          !!u.UserRole.find((ur) => ur.RoleID == 1)
      )
      // if (this.selectedUser.ID > 0 && this.selectedUser.AdministratorID > 0) {
      //   let selectedAdmin = this.userLookup.find(
      //     (u) => u.ID == this.selectedUser.AdministratorID
      //   )index
      //   if (
      //     !administrators.find(
      //       (v) => v.ID == this.selectedUser.AdministratorID
      //     ) &&
      //     selectedAdmin
      //   ) {
      //     administrators.push(selectedAdmin)
      //   }
      // }
      administrators.sort((a, b) => {
        a.FullName > b.FullName
      })
      return administrators
    },
    availableSupervisors() {
      let supervisors = this.userLookup.filter(
        (u) =>
          (u.DivisionID == this.selectedUser.DivisionID ||
            !!u.UserRole.find((ur) => ur.RoleID == 2)) &&
          !!u.UserRole.find((ur) => ur.RoleID == 3)
      )
      // if (this.selectedUser.ID > 0 && this.selectedUser.SupervisorID > 0) {
      //   let selectedSupervisor = this.userLookup.find(
      //     (u) => u.ID == this.selectedUser.SupervisorID
      //   )
      //   if (
      //     !supervisors.find((v) => v.ID == this.selectedUser.SupervisorID) &&
      //     selectedSupervisor
      //   ) {
      //     supervisors.push(selectedSupervisor)
      //   }
      // }
      supervisors.sort((a, b) => {
        a.FullName > b.FullName
      })
      return supervisors
    },
    availableValidators() {
      let validators = this.userLookup.filter(
        (u) =>
          u.DivisionID == this.selectedUser.DivisionID &&
          !!u.UserRole.find((ur) => ur.RoleID == 4)
      )
      // if (
      //   this.selectedUser.ID > 0 &&
      //   this.selectedUser.TimesheetVerifierID > 0
      // ) {
      //   let selectedVerifier = this.userLookup.find(
      //     (u) => u.ID == this.selectedUser.TimesheetVerifierID
      //   )
      //   if (
      //     !validators.find(
      //       (v) => v.ID == this.selectedUser.TimesheetVerifierID
      //     ) &&
      //     selectedVerifier
      //   ) {
      //     validators.push(selectedVerifier)
      //   }
      // }
      validators.sort((a, b) => {
        a.FullName > b.FullName
      })
      return validators
    },
    loading() {
      return this.searchCount > 0
    },
    selectedUserRoles: {
      get() {
        if (this.selectedUser && this.selectedUser.UserRole) {
          return [...new Set(this.selectedUser.UserRole.map((ur) => ur.RoleID))]
        } else {
          return []
        }
      },
      set(val) {
        if (this.selectedUser.UserRole) {
          this.selectedUser.UserRole.forEach((userrole, index, object) => {
            if (!val.includes(userrole.RoleID)) {
              //need to remove role
              object.splice(index, 1)
            }
          })
          val.forEach((roleId) => {
            if (!this.selectedUser.UserRole.find((ur) => ur.RoleID == roleId)) {
              // need to add role
              this.selectedUser.UserRole.push(
                new UserRole({
                  ID: 0,
                  UserID: this.selectedUser.ID,
                  RoleID: roleId,
                })
              )
            }
          })
        }
      },
    },
  },
  watch: {
    currentUser(nval, oval) {
      if (!!nval) {
        this.bounce(this)
      }
    },
    pagination: {
      handler() {
        this.bounce(this)
      },
      deep: true,
    },
    searchText: {
      handler() {
        this.bounce(this)
      },
    },
    allUsers: {
      handler() {
        this.bounce(this)
      },
    },
  },
  methods: {
    loadRoles: call('role/loadRole'),
    loadDivisions: call('division/loadDivision'),
    loadEmployeeTypes: call('employeeType/loadEmployeeType'),
    loadEmployeePayTypes: call('employeePayType/loadEmployeePayType'),
    loadWorkWeekType: call('workWeekType/loadWorkWeekType'),
    loadEmployeeStatus: call('employeeStatus/loadEmployeeStatus'),
    loadUserLookup: call('user/loadUserLookup'),
    saveUser: call('user/saveUser'),
    handleError: call('errors/handleError'),
    deleteUser: call('user/deleteUser'),
    async validate() {
      this.$refs.userForm.validate()
    },

    editUser(entry) {
      this.selectedUser = new User(entry)
      this.modalText = 'Edit User'
      this.dialog = !this.dialog
    },
    newUser() {
      this.modalText = 'Insert User'
      this.selectedUser = new User({EmployeeStatusID: 1})
      this.dialog = !this.dialog
    },
    deleteConfirm(item) {
      this.selectedUser = item
      this.deleteDialog = true
    },
    callDelete() {
      this.deleteUser(this.selectedUser).then(
        (res) => {
          this.searchUsers()
          this.deleteDialog = false
        },
        (err) => {}
      )
    },
    isActive() {
      return true
    },
    saveUserDetails() {
      this.validate().then(() => {
        if (this.valid) {
          //set exempt employees to salary
          if (this.selectedUser.EmployeeTypeID == 1) {
            this.selectedUser.EmployeePayTypeID = 1
          }
          this.saving = true
          this.saveUser(this.selectedUser.removeRelated()).then(
            (res) => {
              this.loadUserLookup()
              this.searchUsers()
              this.$refs.userForm.reset()
              this.saving = false
            },
            (err) => {
              this.saving = false
            }
          )
          this.dialog = false
        }
      })
    },
    searchUsers() {
      if (this.currentUser) {
        if (!this.pagination.sortBy) {
          this.pagination.sortBy = 'LastName'
          this.pagination.descending = false
        }
        if (!this.searchText) {
          this.searchText = ''
        }
        this.searchCount++
        const url = `UserSearch?pageNum=${this.pagination.page - 1}&pageSize=${
          this.pagination.rowsPerPage
        }&sortProperty=${this.pagination.sortBy}&sortDirection=${
          this.pagination.descending ? 'desc' : 'asc'
        }&searchText=${this.searchText}&allUsers=${this.allUsers}&division=${
          this.currentUser.DivisionID
        }`
        this.$axios.get(url).then(
          (res) => {
            // this.pagination.descending = res.data.sortDirection == 'desc'
            this.pagination.rowsPerPage = res.data.Page.Size
            this.pagination.page = res.data.Page.Number + 1
            this.total = res.data.Page.TotalElements
            this.users = res.data.Entries
            this.searchCount--
          },
          (error) => {
            this.error = error
            this.handleError(error)
            console.error(error)
            this.searchCount--
          }
        )
      }
    },
    bounce: _.debounce((self) => {
      self.searchUsers()
    }, 750),
  },
}
</script>

<!-- STYLES -->
<style lang="scss">
//@import '@design';
</style>

