<template>
  <v-row>
    <v-col sm="12">
      <v-card class="mx-auto">
        <v-list-item class="primary">
          <v-list-item-content>
            <v-list-item-title class="primary headline white--text text-left"
              >Activity Codes</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-card-text>
          <v-row justify="end">
            <v-col sm="auto">
              <v-text-field
                v-model="searchText"
                prepend-inner-icon="mdi-magnify"
                label="Search"
                clearable
              ></v-text-field>
            </v-col>
            <v-col sm="auto" class="mt-4 mr-4">
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn
                    color="primary"
                    v-on="on"
                    fab
                    @click="addActivityCodes"
                    depressed
                    small
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add Activity Code</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-dialog
            persistent
            v-model="dialog"
            @keydown.esc="dialog = false"
            scrollable
            width="850"
          >
            <v-form
              ref="activityCodesForm"
              @submit.prevent
              lazy-validation
              v-model="valid"
            >
              <v-card class="elevation-3">
                <v-card-title class="primary white--text headline">{{
                  modalText
                }}</v-card-title>
                <v-card-text class="pa-4">
                  <v-row>
                    <v-col sm="12">
                      <v-text-field
                        label="Code"
                        v-model="selectedActivityCodes.Code"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12">
                      <v-text-field
                        label="Description"
                        v-model="selectedActivityCodes.Description"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- <v-row>
                    <v-col sm="12">
                      <v-text-field 
                        label="Division I D" 
                        v-model="selectedActivityCodes.DivisionID"
                        type="number"
                     ></v-text-field>
                    </v-col>
                  </v-row>-->
                  <v-row no-gutters>
                    <v-col sm="12">
                      <Division
                        v-model="selectedActivityCodes.DivisionID"
                      ></Division>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12">
                      <v-checkbox
                        label="Is Active"
                        v-model="selectedActivityCodes.IsActive"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <span v-if="selectedActivityCodes.DivisionID == 3">
                    <v-row no-gutters>
                      <v-col sm="12">
                        <LuBillableCategory
                          v-model="selectedActivityCodes.BillableCategoryID"
                        ></LuBillableCategory>
                      </v-col>
                    </v-row>
                  </span>
                  <!-- <v-row v-if="selectedActivityCodes.DivisionID == 3">
                    <v-col sm="12">
                      <v-text-field 
                        label="Billable Category I D" 
                        v-model="selectedActivityCodes.BillableCategoryID"
                        type="number"
                     ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="selectedActivityCodes.DivisionID == 3">
                    <v-col sm="12">
                      <v-text-field 
                        label="Prefix" 
                        v-model="selectedActivityCodes.Prefix"
                      ></v-text-field>
                    </v-col>
                  </v-row>-->
                  <!-- //TODO: The multiselects will need to have the props updated to the correct values
                  We are not able to determine the nested relations for the template generation
                  you will also need to load the appropriate lookup component-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedActivityCodes.LuStandardBudgetHours"
                      selfKey="ActivityCodesID"
                      :selfID="selectedActivityCodes.ID"
                      relatedItemKey="RelationID"
                      joinItemName="LuStandardBudgetHours"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedActivityCodes.ProjectBudgetedHours"
                      selfKey="ActivityCodesID"
                      :selfID="selectedActivityCodes.ID"
                      relatedItemKey="RelationID"
                      joinItemName="ProjectBudgetedHours"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                  <!-- <v-row no-gutters>
                    <v-col sm="12">
                      <LuRelation
                      v-model="selectedActivityCodes.TimeSheetEntry"
                      selfKey="ActivityCodesID"
                      :selfID="selectedActivityCodes.ID"
                      relatedItemKey="RelationID"
                      joinItemName="TimeSheetEntry"
                      ></LuRelation>
                    </v-col>
                  </v-row>-->
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    @click="saveActivityCodesDetails"
                    :disabled="saving"
                    color="primary"
                    >Save</v-btn
                  >
                  <v-btn outlined color="primary" @click="cancelEntry"
                    >Cancel</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-card-text>
        <template>
          <v-data-table
            :headers="filteredHeaders"
            :items="mappedActivityCodes"
            :search="searchText"
            class="pt-4"
            :loading="loading"
            dense
          >
            <template v-slot:item.edit="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn
                    small
                    color="primary"
                    v-on="on"
                    icon
                    @click="editActivityCodes(item)"
                  >
                    <v-icon class="clickable" color="primary"
                      >mdi-pencil</v-icon
                    >
                  </v-btn>
                </template>
                <span>Edit ActivityCodes Information</span>
              </v-tooltip>
            </template>
            <template v-slot:item.delete="{item}">
              <v-tooltip v-if="item.ID > 0" bottom>
                <template v-slot:activator="{on}">
                  <v-btn
                    small
                    color="primary"
                    v-on="on"
                    icon
                    @click="deleteConfirm(item)"
                  >
                    <v-icon class="clickable" color="primary"
                      >mdi-delete</v-icon
                    >
                  </v-btn>
                </template>
                <span>Delete ActivityCodes</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-dialog persistent v-model="deleteDialog" scrollable width="350">
            <v-card class="elevation-3">
              <v-card-title class="primary white--text headline"
                >Confirm?</v-card-title
              >
              <v-card-text class="pa-4">
                <v-row>
                  <v-col sm="12"
                    >Are you sure you want to delete this activityCodes?</v-col
                  >
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" @click="deleteDialog = !deleteDialog"
                  >Cancel</v-btn
                >
                <v-btn @click="callDelete" outlined color="primary"
                  >Delete</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import ActivityCodes from '@classes/ActivityCodes'
//templateTODO: import lookup components you plan to use
import Division from '@components/select/single/Division'
import LuBillableCategory from '@components/select/single/LuBillableCategory'
export default {
  data: () => ({
    valid: true,
    selectedActivityCodes: new ActivityCodes(),
    searchText: '',
    dialog: false,
    deleteDialog: false,
    modalText: '',
  }),
  created() {
    this.loadActivityCodes()
    this.loadLuBillableCategory()
    this.loadDivision()
  },
  components: {
    Division,
    LuBillableCategory,
  },
  computed: {
    ...get('activityCodes', ['activityCodes', 'saving', 'loading']),
    ...get('division', ['division']),
    ...get('luBillableCategory', ['luBillableCategory']),
    mappedActivityCodes() {
      return this.activityCodes.map((item) => {
        return {
          ...item,
          division: this.division.find((x) => x.ID == item.DivisionID) || {},
          billableCategory:
            this.luBillableCategory.find(
              (x) => x.ID == item.BillableCategoryID
            ) || {},
        }
      })
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'ID',
          align: 'center',
          sortable: true,
          value: 'ID',
          visible: false,
        },
        {
          text: 'Code',
          align: 'center',
          sortable: true,
          value: 'Code',
          visible: true,
        },
        {
          text: 'Description',
          align: 'center',
          sortable: true,
          value: 'Description',
          visible: true,
        },
        {
          text: 'Division',
          align: 'center',
          sortable: true,
          value: 'division.DivisionName',
          visible: true,
        },
        {
          text: 'Is Active',
          align: 'center',
          sortable: true,
          value: 'IsActive',
          visible: true,
        },
        {
          text: 'Billable Category',
          align: 'center',
          sortable: true,
          value: 'billableCategory.Description',
          visible: true,
        },
        {
          text: 'Prefix',
          align: 'center',
          sortable: true,
          value: 'Prefix',
          visible: false,
        },
        //{
        //  text: 'Billable Category',
        //  align: 'center',
        //  sortable: true,
        //  //templateFIXME: this is just a guess, and may need changed
        //  value: 'BillableCategory.Description',
        //  visible: true,
        //},
        //{
        //  text: 'Division',
        //  align: 'center',
        //  sortable: true,
        //  //templateFIXME: this is just a guess, and may need changed
        //  value: 'Division.Description',
        //  visible: true,
        //},
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('activityCodes', [
      'saveActivityCodes',
      'deleteActivityCodes',
      'loadActivityCodes',
      'clearLookupCacheAndReloadActivityCodes',
    ]),
    loadDivision: call('division/loadDivision'),
    loadLuBillableCategory: call('luBillableCategory/loadLuBillableCategory'),
    async validate() {
      this.$refs.activityCodesForm.validate()
    },
    editActivityCodes(entry) {
      this.selectedActivityCodes = new ActivityCodes(entry)
      this.modalText = 'Edit ActivityCodes'
      this.dialog = !this.dialog
    },
    addActivityCodes() {
      this.modalText = 'Insert ActivityCodes'
      this.selectedActivityCodes = new ActivityCodes()
      this.dialog = !this.dialog
    },
    deleteConfirm(item) {
      this.selectedActivityCodes = item
      this.deleteDialog = true
    },
    callDelete() {
      this.deleteActivityCodes(this.selectedActivityCodes).then(
        (res) => {
          this.clearLookupCacheAndReloadActivityCodes()
          this.deleteDialog = false
        },
        (err) => {}
      )
    },
    saveActivityCodesDetails() {
      this.validate().then(() => {
        if (this.valid) {
          //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
          this.saveActivityCodes(
            this.selectedActivityCodes.removeRelated()
          ).then(
            (res) => {
              this.clearLookupCacheAndReloadActivityCodes()
              this.selectedActivityCodes = new ActivityCodes()
              this.$refs.activityCodesForm.resetValidation()
            },
            (err) => {}
          )
          this.dialog = false
        }
      })
    },
    cancelEntry() {
      this.dialog = false
      this.selectedActivityCodes = new ActivityCodes()
      this.$refs.activityCodesForm.resetValidation()
    },
  },
}
</script>
<style>
</style>